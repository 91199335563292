<template>
  <div class="w-full max-w-lg relative mx-auto py-8 sm:py-16 px-4">
    <ValidateEmail />
  </div>
</template>

<script>
import ValidateEmail from '@components/Auth/ValidateEmail'

export default {
  components: {
    ValidateEmail
  }
}
</script>
